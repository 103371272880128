import React from 'react';
import Button from 'react-bootstrap/Button';
import './pagination.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) => {
  const onPaginationClick = (direction: 'Prev' | 'Next') => {
    if (direction === 'Prev' && currentPage > 1) {
      onPageChange(currentPage - 1);
    } else if (direction === 'Next' && currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="paginationContainer">
      <Button
        data-testid="prev-button"
        onClick={() => onPaginationClick('Prev')}
        disabled={currentPage <= 1}
      >
        <img
          width="40px"
          height="40px"
          src="https://www.volvocars.com/static/shared/icons/v2/mediacircled-previous-40.svg#light-primary"
          alt="prevBtn"
        />
      </Button>
      <Button
        data-testid="next-button"
        onClick={() => onPaginationClick('Next')}
        disabled={currentPage >= totalPages}
      >
        <img
          width="40px"
          height="40px"
          src="https://www.volvocars.com/static/shared/icons/v2/mediacircled-next-40.svg#light-primary"
          alt="nextBtn"
        />
      </Button>
    </div>
  );
};

export default Pagination;
