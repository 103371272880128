import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UrlResolver from '../urlResolver/urlResolver';
import LandingPage from '../landingPage/landingPage';
import Cookies from '../cookies/cookies';
import PageLayout from '../pageLayout/pageLayout';

function Web() {
  return (
    <BrowserRouter>
      <PageLayout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="*" element={<UrlResolver />} />
        </Routes>
      </PageLayout>
    </BrowserRouter>
  );
}

export default Web;
