import { getHostDependentImageUrl } from 'vcl-common';
import './TakeoverThumbnail.css';

function VideoImage(props: any) {
  const { item } = props;
  const dateObject = new Date(item.startTime as any);
  const localDate = dateObject.toLocaleDateString();

  return (
    <>
      <div className="takeoverThumbnailContainer">
        <div className="takeoverThumbnail">
          <div className="takeoverThumbnailHeading">
            <h2>Featured Webcast</h2>
          </div>
          <div className="takeoverThumbnailImage">
            <a href={item.relativeUrl}>
              <img
                src={getHostDependentImageUrl(item.heroUrl)}
                alt={item.title}
              />
            </a>
          </div>

          <div className="takeoverThumbnailText">
            <a href={item.relativeUrl}>
              <div>{localDate}</div>
              <h3>{item.title}</h3>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoImage;
