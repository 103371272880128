import { IWebcast, VIEWING_STATE } from 'vcl-common';
import { parseISO, isPast, addMinutes, formatDistance } from 'date-fns';

export const calculateDistanceFromTime = (dateString: string) => {
  return formatDistance(getDateFromUTCString(dateString), new Date(), {
    addSuffix: true,
  });
};

export const parseDateToLocale = (dateString: string) => {
  return getDateFromUTCString(dateString).toLocaleDateString(
    navigator.language
  );
};

export const parseTimeToLocale = (dateString: string) => {
  const date = getDateFromUTCString(dateString);
  return date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

// Takes an ISO UTC string, for example '2021-01-07T14:10:03' and converts it to a DateTime object using the browser's locale.
export const getDateFromUTCString = (dateString: any) => {
  // If dateString is an object, it's probably a DateTime already and can be returned as is
  if (dateString && typeof dateString === 'object') {
    return dateString;
  } else if (typeof dateString === 'string') {
    // Add a 'Z' in the end if not already present before parsing
    if (!dateString || dateString.match(/z$/i)) {
      return parseISO(dateString);
    } else {
      return parseISO(dateString + 'Z');
    }
  } else {
    throw Error(
      'Incorrect type, please provide an ISO UTC datetime string or a DateTime object.'
    );
  }
};

/*
 * this function will return date time in locale format e.g. 2021-07-26 12:00
 */
export const parseDateTimeToLocale = (dateString: string) => {
  if (dateString) {
    const date = getDateFromUTCString(dateString);
    return (
      date.toLocaleDateString(navigator.language) +
      ' ' +
      date.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  } else {
    return null;
  }
};

/*
 * this function will return date at time in locale format e.g. 2021-07-26 at 12:00
 */
export const parseDateAtTimeToLocale = (dateString: string) => {
  const date = getDateFromUTCString(dateString);
  return (
    date.toLocaleDateString(navigator.language) +
    ' at ' +
    date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    })
  );
};

export const hasEventStarted = (webcast: IWebcast) => {
  return (
    (webcast.viewingState !== VIEWING_STATE.ended &&
      webcast.viewingState !== VIEWING_STATE.endedredirect &&
      isPast(getDateFromUTCString(webcast.startTime))) ||
    (webcast.useCustomCDSwitchOver &&
      webcast.customCDSwitchOverTime &&
      isPast(getDateFromUTCString(webcast.customCDSwitchOverTime)))
  );
};

export const isEventInFuture = (webcast: IWebcast) => {
  return (
    !isPast(getDateFromUTCString(webcast.startTime)) &&
    (!webcast.useCustomCDSwitchOver ||
      (webcast.useCustomCDSwitchOver &&
        webcast.customCDSwitchOverTime &&
        !isPast(getDateFromUTCString(webcast.customCDSwitchOverTime))))
  );
};

export const hasEventGracePeriodEnded = (
  webcast: IWebcast,
  siteSettings: any
) => {
  return isPast(
    addMinutes(
      getDateFromUTCString(webcast.endTime),
      siteSettings.WebcastEndGracePeriodMinutes
    )
  );
};
