import React, { useEffect } from 'react';
import { IWebcast } from 'vcl-common';
import { ViewingState } from 'vcl-common/dist/interfaces/Entities/webcast';
import { statsMinimal } from './statsMinimal';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import isoCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import './flagIcons.css';
import './statistics.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = (props: { webcast: IWebcast }) => {
  const statsMinimalData = statsMinimal;
  const showStatistics = props.webcast != null && statsMinimal != null; // later add the condition to check if statistics are available for a webcast.

  const [OnlineViewers, setOnlineViewers] = React.useState<number>(0);
  const [totalViews, setTotalViews] = React.useState<number>(0);
  const [topCountries, setTopCountries] = React.useState<any>([]);
  const [topCities, setTopCities] = React.useState<any>([]);
  const [viewsOverTime, setViewsOverTime] = React.useState<any>(null);

  // Top countries
  isoCountries.registerLocale(en);

  useEffect(() => {
    if (showStatistics) {
      setOnlineViewers(statsMinimalData.OnlineViewers);
      setTotalViews(statsMinimalData.TotalViewCount);
      setTopCountries(statsMinimalData.TopCountries);
      setTopCities(statsMinimalData.TopCities);
      setViewsOverTime(statsMinimalData.ViewsOverTime);
    }
  }, [props, props.webcast, showStatistics, statsMinimalData]);

  const roundUpToNearest = (num: number) => {
    const digits = Math.floor(Math.log10(num)) + 1;
    let nearest;

    if (digits <= 2) {
      nearest = 10;
    } else if (digits <= 3) {
      nearest = 100;
    } else {
      nearest = 10 ** (digits - 1);
    }

    return Math.ceil(num / nearest) * nearest;
  };

  const renderViewsOverTime = () => {
    const borderColor = '#3d5881';
    const fontColor = '#000000';
    const fontSize = 14;
    const fontFamily = 'Volvo Novum Light';
    const viewTimeTicksMinutes = '5';

    // Add a zero value to the beginning of the views over time object
    // and increment the following values to make sure the chart starts at 0
    const keyValuePairs = [
      { 0: 0 },
      ...Object.keys(viewsOverTime).map((interval) => {
        const newTick = parseInt(interval) + parseInt(viewTimeTicksMinutes);
        const newEntity = { [newTick]: viewsOverTime[interval] };
        return newEntity;
      }),
    ];
    const ViewsOverTime = Object.assign({}, ...keyValuePairs);

    const labels = Object.keys(ViewsOverTime).map((interval) => {
      const hours = Math.floor((ViewsOverTime[interval] as number) / 60);
      const minutes = (ViewsOverTime[interval] as number) % 60;
      const formattedMinutes =
        minutes < 10 ? `0${minutes}` : minutes.toString();
      return `${hours}:${formattedMinutes}`;
    });
    const values = Object.values(ViewsOverTime);
    const keyValueArray = Object.entries(values);

    // Step 2: Sort the array based on the values
    keyValueArray.sort((a, b) => (b[1] as number) - (a[1] as number)); // Sort in ascending order based on values

    const maxYValue = roundUpToNearest(keyValueArray[0][1] as number);

    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          title: {
            text: 'Views over time (hour:minute)',
            display: true,
            font: {
              size: fontSize,
              family: fontFamily,
            },
          },
          ticks: {
            maxTicksLimit: 5,
            font: {
              size: fontSize,
              family: fontFamily,
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            maxTicksLimit: 5,
            font: {
              size: fontSize,
              family: fontFamily,
            },
          },
          min: 0,
          max: maxYValue,
          grid: {
            display: false,
          },
        },
      },
      borderColor: borderColor,
      color: fontColor,
    };

    const _data = {
      labels: labels,
      datasets: [
        {
          data: values,
          tension: 0.3,
        },
      ],
    };
    return <Line options={options} data={_data} />;
  };

  const getCountryJsx = (country: string, noOfViews: string) => {
    const name = isoCountries.getName(country, 'en');
    return (
      <div className="vl-statistics-item" key={`${country}`}>
        <span className={`fi fi-${country}`}></span>
        <span>{name}</span>
        <span>{noOfViews.toLocaleString()}</span>
      </div>
    );
  };

  const getCityJsx = (city: string, noOfViews: string) => {
    const country = city.substring(0, city.indexOf('|')).toLowerCase();
    const cityName = city.substring(city.indexOf('|') + 1, city.length);
    return (
      <div className="vl-statistics-item" key={`${cityName}`}>
        <span className={`fi fi-${country}`}></span>
        <span>{cityName}</span>
        <span>{noOfViews.toLocaleString()}</span>
      </div>
    );
  };

  return (
    <div className="vl-statistics">
      {!showStatistics ? (
        <div className="vl-statistics-unavailable">
          There are no statistics available for this webcast.
        </div>
      ) : (
        <>
          {viewsOverTime && (
            <div className="vl-views-over-time-container">
              {renderViewsOverTime()}
            </div>
          )}
          <div className="vl-statistics-flex-container">
            <div className="vl-statistics-flex-item">
              <div className="vl-statistics-box">
                <div className="vl-statistics-item vl-statistics-title">
                  {props.webcast.viewingState === ViewingState.Live
                    ? 'Watching now'
                    : 'Total views'}
                </div>
                <div className="vl-statistics-views">
                  {props.webcast.viewingState === ViewingState.Live
                    ? OnlineViewers
                    : totalViews}
                </div>
              </div>
            </div>
            <div className="vl-statistics-flex-item">
              <div className="vl-statistics-item vl-statistics-title">
                Top countries
              </div>
              <div className="vl-statistics-list">
                {topCountries.map((country: any) => {
                  return getCountryJsx(country.Name, country.NoOfViews);
                })}
              </div>
            </div>
            <div className="vl-statistics-flex-item">
              <div className="vl-statistics-item vl-statistics-title">
                Top cities
              </div>
              <div className="vl-statistics-list">
                {topCities.map((city: any) => {
                  return getCityJsx(city.Name, city.NoOfViews);
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Statistics;
