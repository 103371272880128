import { useContext, useEffect, useState } from 'react';
import Web from './mainComponents/web/web';
import { AuthContext, MSALAuthService } from 'vcl-common';
import { MsalProvider } from '@azure/msal-react';

function App() {
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const [init, setInit] = useState(false);

  useEffect(() => {
    (async () => {
      await authService.init();
      setInit(true);
    })();
  }, [authService]);

  return (
    <MsalProvider instance={authService.msalInstance}>
      {init && <Web />}
    </MsalProvider>
  );
}

export default App;
