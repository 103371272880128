import Markdown from 'react-markdown';
import './hero.css';

function Hero(props: any) {
  const hasDescText =
    props.descText &&
    (props.descText.descriptionFieldContainsHTML || props.descText.description);
  return (
    <>
      <div className="heroImgContainer">
        <img src={props.heroImage} alt="heroImage" />
        <div className="hero-text">
          <h1>{props.imgtext}</h1>
        </div>
        {hasDescText &&
          (props.descText.descriptionFieldContainsHTML ? (
            <div
              className="heroDescription"
              dangerouslySetInnerHTML={{
                __html: props.descText.descriptionAsHTML,
              }}
            />
          ) : (
            <div className="heroDescription">
              <Markdown>{props.descText.description}</Markdown>
            </div>
          ))}
      </div>
    </>
  );
}

export default Hero;
