import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './thumbnail.css';
import { IWebcast, getHostDependentImageUrl } from 'vcl-common';

interface ThumbnailProps<IWebcast> {
  items: IWebcast[];
}

const ThumbnailCards = ({ items }: ThumbnailProps<IWebcast>) => (
  <Row className="thumbnail-container1">
    {items.map((item) => {
      const dateObject = new Date(item.startTime as any);
      const localDate = dateObject.toLocaleDateString();

      return (
        <Col sm={7} lg={3} key={item.id}>
          <Card>
            <Card.Body>
              <div className="thumbnailDateChanel">
                <a href={item.relativeUrl}>
                  <div>{localDate}</div>
                </a>
                <a href={item.relativeUrl}>
                  <h3>{item.title}</h3>
                </a>
              </div>
            </Card.Body>
            <a href={item.relativeUrl} className="imgCard">
              <Card.Img
                src={getHostDependentImageUrl(item.thumbnailUrl)}
                alt={item.title}
              />
            </a>
          </Card>
        </Col>
      );
    })}
  </Row>
);

export default ThumbnailCards;
