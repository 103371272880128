import { useEffect } from 'react';
import { BsGoogle } from 'react-icons/bs';
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import './saveTheDate.css';
import { IPageMetadata, IWebcast } from 'vcl-common';

function SaveTheDate(props: {
  pageMetadata: IPageMetadata;
  webcast: IWebcast;
}) {
  useEffect(() => {
    atcb_init();
  }, []);

  const googleText = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${props.webcast.titleCombined}&dates=${props.webcast.startTime}/${props.webcast.endTime}&details=Watch+the+webcast+here:+${props.pageMetadata.urlInfo?.url}&location=live.volvocars.com&sprop&sprop=name:`;

  return (
    <div className="saveTheDateContainer">
      <div className="saveTheDateIcon">
        <div>
          <a
            href={`https://accounts.google.com/v3/signin/identifier?dsh=S671200281%3A1671688386289255&continue=${googleText}&followup=${googleText}&osid=1&passive=1209600&service=cl&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AeAAQh6YQY-4LZM0KCP7tUzN5p63Zo4oTZlhBlho55onR5vnxM1Jxh82aMLkVKyDnNWwuglPtVSewQ`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsGoogle />
          </a>
          <h4>Google</h4>
        </div>

        <div>
          <div className="atcb">
            {`{
            "name":"${props.webcast.titleCombined}", "description":"${props.webcast.description}", "startDate":"${props.webcast.startTime}",
            "endDate":"${props.webcast.endTime}", "startTime":"${props.webcast.startTime}", "endTime":"${props.webcast.endTime}",
            "location":"${props.webcast.location.title}", "label":"", "options":[
            "Apple" ], "timeZone":"Europe/Berlin",
            "iCalFileName":"Volvo-meeting"
            }`}
          </div>
          <h4>Apple</h4>
        </div>

        <div>
          <div className="atcb">
            {`{
            "name":"${props.webcast.titleCombined}", "description":"${props.webcast.description}", "startDate":"${props.webcast.startTime}",
            "endDate":"${props.webcast.endTime}", "startTime":"${props.webcast.startTime}", "endTime":"${props.webcast.endTime}",
            "location":"${props.webcast.location.title}", "label":"", "options":[
            "Outlook.com|ok" ], "timeZone":"Europe/Berlin",
            "iCalFileName":"Volvo-meeting"
            }`}
          </div>
          <h4>Outlook</h4>
        </div>
      </div>
    </div>
  );
}

export default SaveTheDate;
