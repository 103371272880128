import {
  PageMetadataContext,
  Constants,
  VclApi,
  MSALAuthService,
  AuthContext,
} from 'vcl-common';
import { useContext, useState, useCallback, useEffect } from 'react';
import Signin from '../signIn/signin';

interface AuthResult {
  storageKey: string;
  apiId: string;
  user: any;
}

function Authenticator(props: any) {
  const { authenticated, setAuthenticated } = props;
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const pageMetadata: any = useContext(PageMetadataContext);
  const [isOrgAuthenticated, setIsOrgAuthenticated] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);

  const signInType = pageMetadata.signInTypeString.toLowerCase();
  const pageType = pageMetadata.pageTypeString.toLowerCase();
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailTriggered, setEmailTriggered] = useState(false);

  const saveAuthResult = useCallback(
    (authResult: AuthResult) => {
      // Define storage key base on pageType
      let storagePrefix: string | null = null;
      switch (pageType) {
        case 'webcast':
          storagePrefix = Constants.localStorage.apiIdWebcast;
          break;
        case 'channel':
          storagePrefix = Constants.localStorage.apiIdChannel;
          break;
        default:
          console.error('Unsupported page type for storage.');
          return; // Early exit if pageType is not supported
      }

      const storageKey = storagePrefix + authResult.storageKey;
      if (!storageKey) {
        console.error('Storage key is undefined.');
        return; // Prevents setting null or undefined keys in localStorage
      }

      localStorage.setItem(storageKey, JSON.stringify(authResult.apiId));
    },
    [pageType]
  );

  const authOrg = useCallback(async () => {
    console.info('authenticator.js | authOrg');

    const isLoggedIn = await authService.isSignedIn();
    if (!isLoggedIn) {
      await authService.signIn();
      return; // Early exit if not logged in, after attempting to sign in
    }

    // Defining API URL based on page type
    const apiUrlMap: Record<string, string> = {
      webcast: Constants.routes.api.authOrgWebcast,
      channel: Constants.routes.api.authOrgChannel,
    };
    const apiUrl = apiUrlMap[pageType] || '';

    if (!apiUrl) {
      console.error('Unsupported page type for API URL.');
      return; // Early exit if the page type does not match expected types
    }
    const accessToken = await authService.getAccessToken();
    try {
      const api = new VclApi();
      const response: any = await api.get(
        apiUrl,
        { url: window.location.href, id: pageMetadata.entityId },
        true,
        accessToken
      );

      if (!response.data?.success) {
        setIsForbidden(true);
        throw new Error('Org authentication failed');
      }

      setIsOrgAuthenticated(true);

      // Determine further action based on signInType
      if (!['pin', 'orgpin', 'orgemail', 'orgemailpin'].includes(signInType)) {
        saveAuthResult(response.data);
        setAuthenticated(true);
      } else if (['orgemail', 'orgemailpin'].includes(signInType)) {
        if (!emailVerified && signInType === 'orgemailpin') {
          setEmailVerified(true);
          setIsForbidden(false);
        } else if (signInType === 'orgemail') {
          saveAuthResult(response.data);
          setAuthenticated(true);
          setIsForbidden(false);
        }
      } else {
        setAuthenticated(false);
      }
    } catch (error: any) {
      console.error('Error in authOrg:', error);
      const statusCode = error.response?.status;
      setIsForbidden(statusCode === 403);
    }
  }, [
    pageMetadata.entityId,
    pageType,
    signInType,
    emailVerified,
    authService,
    saveAuthResult,
    setAuthenticated,
    setIsForbidden,
    setIsOrgAuthenticated,
    setEmailVerified,
  ]);

  const authEmail = async (event: any) => {
    console.info('authenticator.js | authEmail');
    event.preventDefault();

    // Ensure this function only runs for relevant signInType values
    if (!(signInType === 'email' || signInType === 'emailpin')) {
      console.error('Operation aborted: Incorrect signInType for authEmail.');
      return; // Early exit if signInType is not applicable
    }

    const formData = new FormData(event.currentTarget);
    const name = formData.get('fullname') as string; // Assuming input's name attribute is 'name'
    const email = formData.get('email') as string; // Assuming input's name attribute is 'email'
    const company = formData.get('companyName') as string; // Assuming input's name attribute is 'company'

    if (!name || !email || !company) {
      console.error('Required form fields are missing.');
      setEmailError(true);
      return; // Early exit if essential fields are missing
    }

    try {
      const api = new VclApi();
      const response: any = await api.get(
        Constants.routes.api.authEmailWebcastRegister,
        {
          url: window.location.href,
          id: pageMetadata.entityId,
          name: name,
          email: email,
          company: company,
        },
        false
      );

      if (!response.data || !response.data.success) {
        setEmailError(true);
        setEmailTriggered(false);
        throw new Error('Email authentication failed');
      }

      saveAuthResult(response.data);
      setAuthenticated(false);
      setIsForbidden(false);
      setEmailError(false);
      setEmailTriggered(true);
    } catch (error: any) {
      console.error('Error in authEmail:', error);
      if (error?.response?.status === 403) {
        setIsForbidden(true);
      } else {
        console.error(
          error.response ||
            'Unknown error occurred during email authentication.'
        );
        setIsForbidden(false); // This line might not be necessary unless there's a specific reason to reset this state on non-403 errors.
      }
    }
  };

  const authPin = async (event: any) => {
    console.info('authenticator.js | authPin'); // Corrected log statement

    event.preventDefault();

    // Directly extracting the PIN value from the form element
    const pin = event.target[0].value;
    if (!pin) {
      console.error('PIN is required but was not provided.');
      return; // Early exit if pin is not provided
    }

    try {
      const api = new VclApi();
      const response: any = await api.get(
        Constants.routes.api.authPinWebcast,
        {
          url: window.location.href,
          id: pageMetadata.entityId,
          pin: pin,
        },
        false
      );
      if (!response.data || !response.data.success) {
        throw new Error('PIN authentication failed');
      }

      saveAuthResult(response.data);
      setAuthenticated(true);
    } catch (error: any) {
      console.error('Error in authPin:', error);

      // More resilient error checking
      if (error?.response?.status === 403) {
        setIsForbidden(true);
      } else {
        console.error(
          error.response || 'Unknown error occurred during PIN authentication.'
        );
      }
    }
  };

  const authEmailValidate = useCallback(async () => {
    console.info('authenticator.js | authEmailValidate'); // Corrected log statement

    if (
      !authenticated &&
      ((signInType === 'emailpin' && !emailVerified) || signInType === 'email')
    ) {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const encryptedToken = queryParams.get('t');
        if (!encryptedToken) return; // Early exit if no token

        const decodedToken = decodeURIComponent(encryptedToken);
        if (!decodedToken) return; // Early exit if token can't be decoded

        const api = new VclApi();
        const response: any = await api.get(
          Constants.routes.api.authEmailWebcastValidate,
          {
            url: window.location.href,
            id: pageMetadata.entityId,
            token: decodedToken,
          },
          false
        );

        if (!response.data || !response.data.success) {
          throw new Error('Email authentication failed');
        }

        // Handling the response success
        if (signInType === 'emailpin') {
          setEmailVerified(true);
        } else {
          saveAuthResult(response.data);
          setAuthenticated(true);
        }
      } catch (error: any) {
        console.error('Error in authEmailValidate:', error);
        if (error?.response?.status === 403) {
          setIsForbidden(true);
        } else {
          console.error(error.response); // Only log the error response if different from forbidden
        }
      }
    }
  }, [
    authenticated,
    signInType,
    emailVerified,
    pageMetadata.entityId,
    saveAuthResult,
    setEmailVerified,
    setAuthenticated,
  ]);

  useEffect(() => {
    console.info('authenticator.js | useEffect');

    const orgTypes = ['org', 'orgpin', 'orgemail', 'orgemailpin'];
    const emailTypes = ['email', 'emailpin'];

    if (orgTypes.includes(signInType)) {
      authOrg();
    } else if (emailTypes.includes(signInType)) {
      authEmailValidate();
    }
  }, [signInType, authEmailValidate, authOrg]);

  return (
    <>
      {!authenticated && (
        <>
          {(['pin', 'email', 'emailpin'].includes(signInType) ||
            (['orgemailpin', 'orgemail', 'orgpin'].includes(signInType) &&
              isOrgAuthenticated)) && (
            <Signin
              signInType={signInType}
              emailSignInCallback={authEmail}
              pinSignInCallback={authPin}
              isForbidden={isForbidden}
              emailVerified={emailVerified}
              emailError={emailError}
              emailTriggered={emailTriggered}
            />
          )}
          {['orgemailpin', 'orgemail', 'orgpin'].includes(signInType) &&
            !isOrgAuthenticated &&
            isForbidden && (
              <div>
                <h2>Forbidden</h2>
                <p>You are not allowed to access this page.</p>
              </div>
            )}
        </>
      )}
    </>
  );
}

export default Authenticator;
