import { useEffect, useState } from 'react';
import { IWebcast, getHostDependentImageUrl } from 'vcl-common';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import './featureWebcast.css';
import { FaChevronRight, FaChevronLeft, FaPlay } from 'react-icons/fa';
import { formatDateAndTime } from '../../mainComponents/webcast/webcast';
interface FeatureWebcastProps {
  headerTitle: string;
  fetchItems: () => Promise<{
    items: IWebcast[];
    totalItems: number;
  }>;
}

const FeatureWebcasts = ({ headerTitle, fetchItems }: FeatureWebcastProps) => {
  const [webcasts, setWebcasts] = useState<IWebcast[]>([]);
  const [galleryItems, setGalleryItems] = useState<ReactImageGalleryItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    (async () => {
      const response = await fetchItems();
      setWebcasts(response.items);
      setGalleryItems(
        response.items?.map((item: IWebcast) => {
          return {
            original: item.heroUrl
              ? getHostDependentImageUrl(item.heroUrl)
              : '',
            // thumbnail: getHostDependentImageUrl(item.thumbnailUrl),
            thumbnailTitle: item.title,
            description: item.title,
            renderItem: () =>
              renderCustomItem(
                item.heroUrl ? getHostDependentImageUrl(item.heroUrl) : '',
                item.startTime ?? '',
                item.title
              ),
          };
        }) ?? []
      );
    })();
  }, [fetchItems]);

  const CustomLeftNav = (onClick: any, disabled: any) => {
    return (
      <button
        className="image-gallery-custom-nav image-gallery-custom-left-nav"
        disabled={false}
        onClick={onClick}
      >
        <FaChevronLeft />
      </button>
    );
  };

  const CustomRightNav = (onClick: any, disabled: any) => {
    return (
      <button
        className="image-gallery-custom-nav image-gallery-custom-right-nav"
        disabled={false}
        onClick={onClick}
      >
        <FaChevronRight />
      </button>
    );
  };

  const handleSlide = (index: number) => {
    setCurrentIndex(index);
  };

  const handleImageClick = () => {
    console.log(`Current index: ${currentIndex}`);
    window.open(webcasts[currentIndex].relativeUrl, '_blank');
  };

  const renderCustomItem = (
    imageUrl: string,
    startTime: string,
    title: string
  ) => {
    return (
      <>
        <div className="image-gallery-image-wrapper">
          <img src={imageUrl} alt="" />
          <div className="play-icon-wrapper">
            <FaPlay className="play-icon" />
          </div>
        </div>

        <div className="image-title">
          <div className="image-title__date">
            {formatDateAndTime(startTime, false)}
          </div>
          <h2>{title}</h2>
        </div>
      </>
    );
  };

  return (
    <>
      {galleryItems.length === 0 ? null : (
        <>
          <h1 className="sliderTitle">{headerTitle}</h1>
          <div className="slider_container">
            <ImageGallery
              items={galleryItems}
              showBullets={true}
              showThumbnails={false} // Hide thumbnails
              showPlayButton={false}
              showFullscreenButton={false}
              showNav={true}
              showIndex={true}
              renderLeftNav={CustomLeftNav}
              renderRightNav={CustomRightNav}
              additionalClass="custom-gallery" // Custom class for additional styling
              autoPlay
              onSlide={handleSlide}
              onClick={handleImageClick}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FeatureWebcasts;
