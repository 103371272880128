import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import SideNavbar from '../sideNavbar/sideNavbar';
import { Link } from 'react-router-dom';
import './header.css';

function Header() {
  return (
    <>
      <Navbar bg="light">
        <Container fluid className="headerContainer">
          <Navbar.Brand as={Link} to="/">
            <img
              src="https://www.volvocars.com/static/shared/images/volvo-wordmark-black.svg"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <SideNavbar />
    </>
  );
}

export default Header;
