import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { createRoot } from 'react-dom/client';
import App from './App';
import { loginRequest, msalConfig } from './utils/msalConfig';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './master.css';
import { AuthContext, MSALAuthService } from 'vcl-common';

export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error(JSON.stringify(event));
  }
});

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AuthContext.Provider
    value={{
      authService: new MSALAuthService(msalConfig, loginRequest),
    }}
  >
    <App />
  </AuthContext.Provider>
);

reportWebVitals();
