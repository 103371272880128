import Header from '../../sharedComponents/header/header';
import Footer from '../../sharedComponents/footer/footer';

function PageLayout(props: any) {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
}

export default PageLayout;
