import Hero from '../../sharedComponents/hero/hero';

function Cookies(props: any) {
  return (
    <>
      <Hero heroImage="/volvoHeroimg.jpg" imgtext="Cookies" />
    </>
  );
}

export default Cookies;
