export const statsMinimal = {
  TopCountries: [
    {
      Name: 'se',
      NoOfViews: 423,
    },
    {
      Name: 'cn',
      NoOfViews: 218,
    },
    {
      Name: 'us',
      NoOfViews: 65,
    },
    {
      Name: 'gb',
      NoOfViews: 41,
    },
    {
      Name: 'de',
      NoOfViews: 35,
    },
  ],
  TopCities: [
    {
      Name: 'se|Malmö',
      NoOfViews: 242,
    },
    {
      Name: 'cn|Shanghai',
      NoOfViews: 116,
    },
    {
      Name: 'se|Stockholm',
      NoOfViews: 114,
    },
    {
      Name: 'se|Gothenburg',
      NoOfViews: 43,
    },
    {
      Name: 'gb|London',
      NoOfViews: 28,
    },
  ],
  AccessKey: 'vclwrm1sc',
  TotalViewCount: 1055,
  OnlineViewers: 50,
  ViewsOverTime: {
    '0': 931,
    '5': 531,
    '10': 463,
    '15': 426,
    '20': 373,
    '25': 350,
    '30': 327,
    '35': 306,
    '40': 281,
    '45': 191,
    '50': 0,
    '55': 0,
  },
};
