import { useContext, useEffect, useState } from 'react';
import {
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
  FaTwitter,
} from 'react-icons/fa';
import { VscChevronRight, VscChevronLeft } from 'react-icons/vsc';
import './sideNavbar.css';
import {
  AuthContext,
  Constants,
  IUserInfo,
  MSALAuthService,
  VclApi,
} from 'vcl-common';
import ProfileCard from '../profile/profileCard';

function SideNavbar() {
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    isWebcastAdminOfAny: false,
    isRegionAdminOfAny: false,
    isChannelAdminOfAny: false,
    isOrgAdminOfAny: false,
    isGlobalAdmin: false,
  });
  const [profile, setProfile] = useState({ name: '', pic: '' });
  const [menuVisible, setMenuVisible] = useState(false);
  const [adminMenuVisible, setAdminMenuVisible] = useState(false);

  const fetchProfile = async (accessToken: any) => {
    const api = new VclApi();
    let currentUserRolesResponse: any = localStorage.getItem(
      Constants.localStorage.currentUserRoles
    );
    let currentUserRepsonse: any = localStorage.getItem(
      Constants.localStorage.currentUser
    );
    if (currentUserRolesResponse != null && currentUserRepsonse != null) {
      currentUserRolesResponse = JSON.parse(currentUserRolesResponse);
      currentUserRepsonse = JSON.parse(currentUserRepsonse);
      setProfile({
        name: currentUserRepsonse.displayName,
        pic: currentUserRepsonse.profilePic,
      });
      setUserInfo(currentUserRolesResponse);
    } else {
      const response = await api.get(
        Constants.routes.api.me,
        {},
        true,
        accessToken
      );
      if (response.data) {
        setProfile({
          name: response.data.user?.displayName,
          pic: response.data.profilePic,
        });
        setUserInfo(response.data.userInfo);
        const _user = {
          displayName: response.data.user?.displayName,
          mail: response.data.user?.userPrincipalName,
          companyName: response.data.user?.companyName,
          profilePic: response.data.profilePic,
        };
        localStorage.setItem(
          Constants.localStorage.currentUserRoles,
          JSON.stringify(response.data.userInfo)
        );
        localStorage.setItem(
          Constants.localStorage.currentUser,
          JSON.stringify(_user)
        );
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!isSignedIn && menuVisible) {
        console.log('Checking login status ' + isSignedIn);
        const loginStatus = await authService.isSignedIn();
        if (loginStatus) {
          const accessToken = await authService.getAccessToken();
          await fetchProfile(accessToken);
          setIsSignedIn(true);
        }
      }
    })();
  }, [isSignedIn, authService, menuVisible]);

  const toggleMenu = () => setMenuVisible((prev) => !prev);
  const toggleAdminMenu = () => setAdminMenuVisible((prev) => !prev);

  const adminAccess =
    userInfo.isGlobalAdmin ||
    userInfo.isOrgAdminOfAny ||
    userInfo.isRegionAdminOfAny ||
    userInfo.isChannelAdminOfAny;

  const signIn = () => authService.signIn();
  const signOut = () => authService.signOut();

  return (
    <>
      <div className="sideNavbarContainer">
        <button
          className="btn menuBtn"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
          onClick={toggleMenu}
        >
          Menu
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header sideHeaderContainer">
            <img
              src="https://www.volvocars.com/static/shared/images/volvo-wordmark-black.svg"
              className="d-inline-block align-top"
              alt="logo"
            />
            {adminMenuVisible && (
              <VscChevronLeft
                className="sideNavbarLeftBtn"
                onClick={toggleAdminMenu}
              />
            )}
            <h6>{adminMenuVisible ? 'Admin' : ''}</h6>
            <button
              className="btn-close text-reset sideNavbarCloseBtn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={toggleMenu}
            ></button>
          </div>
          <div className="offcanvas-body">
            {!adminMenuVisible && (
              <div className="sideNavbarMenu">
                {isSignedIn ? (
                  <>
                    {profile.name && (
                      <ProfileCard name={profile.name} imageUrl={profile.pic} />
                    )}
                    <div className="navbarMenuTab" onClick={() => signOut()}>
                      Sign Out
                    </div>
                  </>
                ) : (
                  <div className="navbarMenuTab" onClick={() => signIn()}>
                    Sign In
                  </div>
                )}
                {adminAccess && (
                  <div
                    className="navbarMenuTab sideNavbarrightIcon"
                    onClick={toggleAdminMenu}
                  >
                    Admin <VscChevronRight />
                  </div>
                )}
                <div className="navbarMenuTab">Support</div>
                <div className="sideNavbarSocialIcons">
                  <FaFacebookSquare />
                  <FaInstagram />
                  <FaTwitter />
                  <FaYoutube size="30px" />
                </div>
              </div>
            )}
            {adminMenuVisible && (
              <div className="sideNavbarMenu">
                <div
                  className="navbarMenuTab"
                  onClick={() =>
                    window.open(
                      `${process.env['REACT_APP_ADMINWEB_URL'] as string}`,
                      '_blank'
                    )
                  }
                >
                  Admin Center
                </div>
                <div className="navbarMenuTab">How-to guides</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNavbar;
