import React from 'react';
import './profileCard.css';
const dummyPic =
  'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';

function ProfileCard({ name, imageUrl }: { name: string; imageUrl: string }) {
  return (
    <div className="profile-container">
      <img
        src={imageUrl ? imageUrl : dummyPic}
        alt="User Profile"
        className="profile-image"
      />
      <h5 className="profile-name">{name}</h5>
    </div>
  );
}

export default ProfileCard;
