import './footer.css';
function Footer() {
  return (
    <>
      <footer>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://live.volvocars.com/cookies"
            >
              Cookies
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.volvocars.com/intl/footer/legal"
            >
              Legal
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.volvocars.com/intl/footer/privacy"
            >
              Privacy{' '}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.volvocars.com/intl/footer/social-media"
            >
              Social Media{' '}
            </a>
          </li>
        </ul>
        <div className="footerCopyrightText">
          Copyright © 2022 Volvo Car Corporation (or its affiliates or
          licensors)
        </div>
      </footer>
    </>
  );
}

export default Footer;
