import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound from '../notFound/notFound';
import Webcast from '../webcast/webcast';
import Channel from '../channel/channel';
import Authenticator from '../authenticator/authenticator';
import { PageMetadataContext, Constants, VclApi } from 'vcl-common';

function UrlResolver() {
  const location = useLocation();
  const navigate = useNavigate();

  const [pageMetadata, setPageMetadata] = useState<any>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  // Try and get the API ID from local storage. If an API ID exists, it means the user has been authenticated to this page before.
  // Start with looking for an access key (= webcast)
  // If no access key is found, assume it's a channel and try to parse the channel name from the URL
  let apiId: any = null;
  const accessKeyMatch = document.location.pathname.match(/(vcl[a-z0-9]{6})/gi);
  if (accessKeyMatch) {
    const accessKey = accessKeyMatch[accessKeyMatch.length - 1];
    apiId = JSON.parse(
      localStorage.getItem(
        Constants.localStorage.apiIdWebcast + accessKey
      ) as string
    );
  } else {
    const pathArr = document.location.pathname.split('/');
    if (pathArr.length > 1)
      apiId = JSON.parse(
        localStorage.getItem(
          Constants.localStorage.apiIdChannel +
            pageMetadata?.globalSettings?.organization?.id +
            '-' +
            pathArr[pathArr.length - 1]
        ) as string
      );
  }

  const getPageMetadata = useCallback(async () => {
    console.info('urlResolver.js | getPageMetadata');

    try {
      // The pageMetadata API takes the current URL and determines what type of page this is
      const params: any = { url: window.location.href };

      // If an API ID was found, add it to the API params
      if (apiId) params.apiId = apiId;

      const api = new VclApi();
      const response: any = await api.get(
        Constants.routes.api.pageMetadata,
        params,
        false
      );

      if (
        response.status === 400 ||
        !response.data ||
        (response.data.urlInfo && !response.data.urlInfo.isValid)
      ) {
        throw new Error('Invalid page metadata');
      }

      const pageMetadata: any = response.data;
      setPageMetadata(pageMetadata);
    } catch (error) {
      console.error(error);
    }
  }, [apiId]);

  let childComponent = null;
  if (pageMetadata) {
    const pageType = pageMetadata.pageTypeString.toLowerCase();

    // The pageMetadata object shows which type of page this is. If the page requires authentication
    // and no metadata is returned, it means either the user isn't authenticated or provided the wrong
    // API ID. In that case, authenticate.
    if (pageMetadata.urlInfo === null && pageMetadata.needsAuthentication) {
      console.info('urlResolver.js | authentication required');
      childComponent = (
        <Authenticator
          authenticated={authenticated}
          setAuthenticated={setAuthenticated}
        />
      );
    } else if (pageType === 'webcast') {
      // If the current URL doesn't match the "correct" URL according to the metadata, navigate the user to the correct URL
      if (
        location.pathname.toLowerCase() !==
        pageMetadata.urlInfo.webcast.relativeUrl.toLowerCase()
      ) {
        navigate(pageMetadata.urlInfo.webcast.relativeUrl.toLowerCase());
      } else {
        childComponent = <Webcast />;
      }
    } else if (pageType === 'channel') {
      if (
        location.pathname.toLowerCase() !==
        pageMetadata.urlInfo.channel.relativeUrl.toLowerCase()
      ) {
        navigate(pageMetadata.urlInfo.channel.relativeUrl.toLowerCase());
      } else {
        childComponent = <Channel />;
      }
    } else {
      childComponent = <NotFound />;
    }
  }

  useEffect(() => {
    console.info('urlResolver.js | useEffect');
    getPageMetadata();
  }, [getPageMetadata]);

  return (
    <>
      {childComponent && (
        <PageMetadataContext.Provider value={pageMetadata}>
          {childComponent}
        </PageMetadataContext.Provider>
      )}
    </>
  );
}

export default UrlResolver;
