import { useEffect, useState, useRef } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './countdown.css';
import { utcToZonedTime } from 'date-fns-tz';
import { isBefore } from 'date-fns';

type ICountdownProps = {
  startDate: Date;
  customDate?: Date | null;
  switchToStream: () => void;
};

type ICountdownTimer = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const Countdown = ({
  startDate,
  customDate,
  switchToStream,
}: ICountdownProps) => {
  const [timer, setTimer] = useState<ICountdownTimer>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const intervalRef = useRef<any>(null);

  const countdownDate = utcToZonedTime(new Date(startDate), 'UTC');
  let countdownCustomDate = countdownDate;
  if (customDate) {
    countdownCustomDate = utcToZonedTime(new Date(customDate), 'UTC');
  }

  useEffect(() => {
    const calculateTimeleft = () => {
      if (countdownDate) {
        const currentTime = utcToZonedTime(new Date(), 'UTC').getTime();
        const distanceToDate = countdownDate.getTime() - currentTime;
        const distanceToCustomDate =
          countdownCustomDate.getTime() - currentTime;
        if (distanceToDate <= 0 || distanceToCustomDate <= 0) {
          return null;
        } else {
          const days: any = Math.floor(distanceToDate / (3600 * 24 * 1000));
          const hours: any = Math.floor(
            (distanceToDate % (3600 * 24 * 1000)) / (3600 * 1000)
          );
          const minutes: any = Math.floor(
            (distanceToDate % (3600 * 1000)) / (60 * 1000)
          );
          const seconds: any = Math.floor(
            (distanceToDate % (60 * 1000)) / 1000
          );

          return { days, hours, minutes, seconds };
        }
      }
    };

    intervalRef.current = setInterval(() => {
      const updatedTimer = calculateTimeleft();
      //If diff is less than or equal to zero it returns null else object;
      if (updatedTimer) {
        setTimer(updatedTimer);
        if (
          (customDate &&
            isBefore(countdownCustomDate, utcToZonedTime(new Date(), 'UTC'))) ||
          isBefore(countdownDate, utcToZonedTime(new Date(), 'UTC'))
        ) {
          switchToStream();
        }
      } else {
        clearInterval(intervalRef.current);
        switchToStream();
      }
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [countdownDate, countdownCustomDate, switchToStream, customDate]);

  // Convert single digit numbers to strings with leading zeros
  const addLeadingZero = (number: number): string => {
    return number < 10 ? `0${number}` : `${number}`;
  };

  return (
    <>
      {timer.days === 0 &&
      timer.hours === 0 &&
      timer.minutes === 0 &&
      timer.seconds === 0 ? (
        <></>
      ) : (
        <div>
          <div className="countdown-text">
            <h5>Starting in</h5>
          </div>
          <div className="countdown-wrapper">
            {timer.days > 0 && (
              <div className="circularProgressBar">
                <CircularProgressbarWithChildren
                  value={timer.days * (100 / 365)}
                  counterClockwise={true}
                  strokeWidth={4}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#fff',
                    textColor: '#f88',
                    trailColor: '#d6d6d6',
                    backgroundColor: 'blue',
                  })}
                >
                  <div style={{ fontSize: 20, marginTop: -5 }}>
                    <strong>{addLeadingZero(timer.days)}</strong>
                  </div>
                  <div style={{ fontSize: 8, marginTop: -5 }}>
                    <small>DAYS</small>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )}
            {!(timer.days === 0 && timer.hours === 0) && (
              <div className="circularProgressBar">
                <CircularProgressbarWithChildren
                  value={timer.hours * (100 / 60)}
                  counterClockwise={true}
                  strokeWidth={4}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#fff',
                    textColor: '#f88',
                    trailColor: '#d6d6d6',
                    backgroundColor: 'blue',
                  })}
                >
                  <div style={{ fontSize: 20, marginTop: -5 }}>
                    <strong>{addLeadingZero(timer.hours)}</strong>
                  </div>
                  <div style={{ fontSize: 8, marginTop: -5 }}>
                    <small>HOURS</small>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )}
            {!(
              timer.days === 0 &&
              timer.hours === 0 &&
              timer.minutes === 0
            ) && (
              <div className="circularProgressBar">
                <CircularProgressbarWithChildren
                  value={timer.minutes * (100 / 60)}
                  counterClockwise={true}
                  strokeWidth={4}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#fff',
                    textColor: '#f88',
                    trailColor: '#d6d6d6',
                    backgroundColor: 'blue',
                  })}
                >
                  <div style={{ fontSize: 20, marginTop: -5 }}>
                    <strong>{addLeadingZero(timer.minutes)}</strong>
                  </div>
                  <div style={{ fontSize: 8, marginTop: -5 }}>
                    <small>Minutes</small>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )}
            {!(
              timer.days === 0 &&
              timer.hours === 0 &&
              timer.minutes === 0 &&
              timer.seconds === 0
            ) && (
              <div className="circularProgressBar">
                <CircularProgressbarWithChildren
                  value={timer.seconds * (100 / 60)}
                  counterClockwise={true}
                  strokeWidth={4}
                  styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#fff',
                    textColor: '#f88',
                    trailColor: '#d6d6d6',
                    backgroundColor: 'blue',
                  })}
                >
                  <div style={{ fontSize: 20, marginTop: -5 }}>
                    <strong>{addLeadingZero(timer.seconds)}</strong>
                  </div>
                  <div style={{ fontSize: 8, marginTop: -5 }}>
                    <small>SECONDS</small>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Countdown;
